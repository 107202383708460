import React from 'react';

function Projects() {
  // Your component code
    return (
        <div className="p-4">
        <h1 className="text-2xl font-bold">Projects</h1>
        <p className="mt-2">This is the Projects page.</p>
        </div>
    );
}

export default Projects;